<template>
    <div>
        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="3">
                        <b-form-group labe-for="Reports" label="Choose Transport Report">
                            <validation-provider #default="{ errors }" name="report_type" rules="">
                                <b-form-select id="report_type" name="report_type" size="md" v-model="report_type">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option value="1">
                                        Transport Rebail Tags Report
                                    </b-form-select-option>
                                    <b-form-select-option value="2">
                                        Transport Summary
                                    </b-form-select-option>
                                    <b-form-select-option value="3">
                                        Transporter Details
                                    </b-form-select-option>
                                    <b-form-select-option value="4">
                                        Dispatch Details
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.report_type">{{
                                serverErrors.report_type[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <!-- For Transport Rebail Tag -->
                <b-row v-if="report_type === '1'">
                    <b-col cols="2" md="1">
                        <label>
                            Season <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{ serverErrors.season_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="season_id"
                            @change="changeSeason()"
                            :disabled="seasons.length <= 1"
                        >
                            <b-form-select-option :value="null" v-if="seasons.length > 1">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="season in seasons"
                                :key="season.id"
                                :value="season.id"
                            >
                                {{ season.startYear }}/{{ season.endYear }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="3" md="2">
                        <label>
                            Central Purchase Point <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{ serverErrors.market_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="market_id"
                            @change="changeMarket()"
                        >
                            <b-form-select-option
                                :value="null"
                                selected
                            >
                                Select...
                            </b-form-select-option>
                            <b-form-select-option
                                v-for="market in markets"
                                :key="market.id"
                                :value="market.id"
                            >
                                {{ market.code.toUpperCase() }}: {{ market.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    
                    <b-col cols="2" md="2">
                        <label>
                         From <small class="text-danger"></small> 
                        </label>
                                    <validation-provider #default="{ errors }" name="StartDate" rules="">
                                        <b-form-datepicker
                                            id="startDate" v-model="startDate" name="startDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.startDate">{{ serverErrors.startDate[0] }}</small>
                                    </validation-provider>
                            </b-col>
                            <b-col cols="2" md="2">
                                <label>
                                    To <small class="text-danger"></small> 
                                    </label> 
                                    <validation-provider #default="{ errors }" name="End Date" rules="">
                                        <b-form-datepicker
                                            id="endDate" v-model="endDate" name="endDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.endDate">{{ serverErrors.endDate[0] }}</small>
                                    </validation-provider>
                            </b-col>

                    <b-col cols="6" md="2" class="pt-2">
                        <label>
                                     <small class="text-danger"></small> 
                                    </label> 
                        <b-button
                            variant="outline-success"
                            size="sm"
                            @click="generateReport()"
                        >
                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                            <span class="text-nowrap">Generate</span>
                            <feather-icon icon="DownloadCloudIcon" class="ml-1"/>
                        </b-button>
                    </b-col>
                </b-row>
                 
                <!-- For Transport Summary -->
                <b-row v-if="report_type === '2'">
                    <b-col cols="2" md="1">
                        <label>
                            Season <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{ serverErrors.season_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="season_id"
                            @change="changeSeason()"
                            :disabled="seasons.length <= 1"
                        >
                            <b-form-select-option :value="null" v-if="seasons.length > 1">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="season in seasons"
                                :key="season.id"
                                :value="season.id"
                            >
                                {{ season.startYear }}/{{ season.endYear }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="3" md="2">
                        <label>
                            Central Purchase Point <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{ serverErrors.market_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="market_id"
                            @change="changeMarket()"
                        >
                            <b-form-select-option
                                :value="null"
                                selected
                            >
                                Select...
                            </b-form-select-option>
                            <b-form-select-option
                                v-for="market in markets"
                                :key="market.id"
                                :value="market.id"
                            >
                                {{ market.code.toUpperCase() }}: {{ market.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    
                    <b-col cols="2" md="2">
                        <label>
                         From <small class="text-danger"></small> 
                        </label>
                                    <validation-provider #default="{ errors }" name="StartDate" rules="">
                                        <b-form-datepicker
                                            id="startDate" v-model="startDate" name="startDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.startDate">{{ serverErrors.startDate[0] }}</small>
                                    </validation-provider>
                            </b-col>
                            <b-col cols="2" md="2">
                                <label>
                                    To <small class="text-danger"></small> 
                                    </label> 
                                    <validation-provider #default="{ errors }" name="End Date" rules="">
                                        <b-form-datepicker
                                            id="endDate" v-model="endDate" name="endDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.endDate">{{ serverErrors.endDate[0] }}</small>
                                    </validation-provider>
                    </b-col>

                    <b-col cols="6" md="2">
                        <label>
                            Consignment Number: <small class="text-danger" v-if="serverErrors && serverErrors.grower_id">{{ serverErrors.cNumber[0] }}</small>
                        </label>
                        <b-form-input
                            id="cNumber"
                            name="cNumber"
                            size="sm"
                            v-model="cNumber"
                        /> 
                    </b-col>

                    <b-col cols="6" md="2" class="pt-2">
                        <label>
                                     <small class="text-danger"></small> 
                                    </label> 
                        <b-button
                            variant="outline-success"
                            size="sm"
                            @click="generateReport2()"
                        >
                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                            <span class="text-nowrap">Generate</span>
                            <feather-icon icon="DownloadCloudIcon" class="ml-1"/>
                        </b-button>
                    </b-col>
                </b-row>
                
                <!-- For Transporter Details -->
                <b-row v-if="report_type === '3'">
                    <b-col cols="2" md="1">
                        <label>
                            Season <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{ serverErrors.season_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="season_id"
                            @change="changeSeason()"
                            :disabled="seasons.length <= 1"
                        >
                            <b-form-select-option :value="null" v-if="seasons.length > 1">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="season in seasons"
                                :key="season.id"
                                :value="season.id"
                            >
                                {{ season.startYear }}/{{ season.endYear }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="3" md="2">
                        <label>
                            Central Purchase Point <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{ serverErrors.market_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="market_id"
                            @change="changeMarket()"
                        >
                            <b-form-select-option
                                :value="null"
                                selected
                            >
                                Select...
                            </b-form-select-option>
                            <b-form-select-option
                                v-for="market in markets"
                                :key="market.id"
                                :value="market.id"
                            >
                                {{ market.code.toUpperCase() }}: {{ market.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    
                    <b-col cols="2" md="2">
                        <label>
                         From <small class="text-danger"></small> 
                        </label>
                                    <validation-provider #default="{ errors }" name="StartDate" rules="">
                                        <b-form-datepicker
                                            id="startDate" v-model="startDate" name="startDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.startDate">{{ serverErrors.startDate[0] }}</small>
                                    </validation-provider>
                            </b-col>
                            <b-col cols="2" md="2">
                                <label>
                                    To <small class="text-danger"></small> 
                                    </label> 
                                    <validation-provider #default="{ errors }" name="End Date" rules="">
                                        <b-form-datepicker
                                            id="endDate" v-model="endDate" name="endDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.endDate">{{ serverErrors.endDate[0] }}</small>
                                    </validation-provider>
                    </b-col>

                    <b-col cols="6" md="2">
                        <label>
                            Transporter Name: <small class="text-danger" v-if="serverErrors && serverErrors.grower_id">{{ serverErrors.transporter_name_id[0] }}</small>
                        </label>
                        <b-form-input
                            id="transporter_name"
                            name="transporter_name"
                            size="sm"
                            v-model="transporter_name"
                        /> 
                    </b-col>

                    <b-col cols="6" md="2" class="pt-2">
                        <label>
                                     <small class="text-danger"></small> 
                                    </label> 
                        <b-button
                            variant="outline-success"
                            size="sm"
                            @click="generateReport3()"
                        >
                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                            <span class="text-nowrap">Generate</span>
                            <feather-icon icon="DownloadCloudIcon" class="ml-1"/>
                        </b-button>
                    </b-col>
                </b-row>

                <!-- For Dispatch Report    -->
                  
                <b-row v-if="report_type === '4'">
                    <b-col cols="2" md="1">
                        <label>
                            Season <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{ serverErrors.season_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="season_id"
                            @change="changeSeason()"
                            :disabled="seasons.length <= 1"
                        >
                            <b-form-select-option :value="null" v-if="seasons.length > 1">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="season in seasons"
                                :key="season.id"
                                :value="season.id"
                            >
                                {{ season.startYear }}/{{ season.endYear }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="3" md="2">
                        <label>
                            Central Purchase Point <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{ serverErrors.market_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="market_id"
                            @change="changeMarket()"
                        >
                            <b-form-select-option
                                :value="null"
                                selected
                            >
                                Select...
                            </b-form-select-option>
                            <b-form-select-option
                                v-for="market in markets"
                                :key="market.id"
                                :value="market.id"
                            >
                                {{ market.code.toUpperCase() }}: {{ market.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    
                    <b-col cols="2" md="2">
                        <label>
                         From <small class="text-danger"></small> 
                        </label>
                                    <validation-provider #default="{ errors }" name="StartDate" rules="">
                                        <b-form-datepicker
                                            id="startDate" v-model="startDate" name="startDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.startDate">{{ serverErrors.startDate[0] }}</small>
                                    </validation-provider>
                            </b-col>
                            <b-col cols="2" md="2">
                                <label>
                                    To <small class="text-danger"></small> 
                                    </label> 
                                    <validation-provider #default="{ errors }" name="End Date" rules="">
                                        <b-form-datepicker
                                            id="endDate" v-model="endDate" name="endDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.endDate">{{ serverErrors.endDate[0] }}</small>
                                    </validation-provider>
                        </b-col>

                    <b-col cols="6" md="2" class="pt-2">
                        <label>
                                     <small class="text-danger"></small> 
                                    </label> 
                        <b-button
                            variant="outline-success"
                            size="sm"
                            @click="generateReport4()"
                        >
                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                            <span class="text-nowrap">Generate</span>
                            <feather-icon icon="DownloadCloudIcon" class="ml-1"/>
                        </b-button>
                    </b-col>
                </b-row>


            </b-card-body>
        </b-card>
        <!-- ./Filters -->
    </div>
</template>



<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BBadge,
    } from 'bootstrap-vue'

    import store from '@/store'
    import { ref, onUnmounted, onMounted } from '@vue/composition-api'
    import vSelect from 'vue-select'
    import growerVoucherStoreModule from '@/views/cromis/reports/farmers/growerVoucherStoreModule'
    import axios from '@axios'
    
    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
            BSpinner, BBadge,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context){
            const dataForm = ref(null)
            const myModal = ref(null)
            const saving = ref(false)
            const serverErrors = ref({})
            const seasons = ref([])
            const season_id = ref(null)
            const markets = ref([])
            const market_id = ref(null)
            const startDate = ref(null)
            const endDate = ref(null)
            const sales = ref([])
            const sale_id = ref(null)
            const growers = ref([])
            const grower_id = ref(null)
            const number = ref(null)
            const report_type = ref(null)
            const cNumber = ref(null)
            const transporter_name = ref(null)

            const CROMIS_STORE_MODULE_NAME = 'cromis-growerVoucher'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, growerVoucherStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-growerVoucher/seasons')
                            .then(response => {
                                seasons.value = response.data.seasons
                                if(seasons.value.length === 1){
                                    season_id.value = seasons.value[0].id
                                    changeSeason()
                                }
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
            })

            const changeSeason = async () => {
                if(season_id.value){
                    market_id.value = null
                    await store.dispatch('cromis-growerVoucher/markets', { season_id: season_id.value, report: true })
                                .then(response => {
                                    markets.value = response.data.markets
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }
                else{
                    if(markets.value.length > 0){
                        markets.value.splice(0)
                    }
                }

                serverErrors.value.season_id = null
            }

            const changeMarket = async () => {
                sale_id.value = null
                if(market_id.value){
                    await store.dispatch('cromis-growerVoucher/sales', { season_id: season_id.value, market_id: market_id.value, report: true })
                                .then(response => {
                                    sales.value = response.data.sales
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }
                else{
                    if(sales.value.length > 0){
                        sales.value.splice(0)
                    }
                }

                serverErrors.value.market_id = null
            }

            const changeSale = async () => {
                grower_id.value = null
                number.value = null
                if(sale_id.value){
                    await store.dispatch('cromis-growerVoucher/growers', { sale_id: sale_id.value, report: true })
                                .then(response => {
                                    growers.value = response.data.growers
                                })
                                .catch(error => {
                                    console.log('got error', error)
                                })
                }
                else{
                    if(growers.value.length > 0){
                        growers.value.splice(0)
                    }
                }

                serverErrors.value.sale_id = null
            }

            const generateReport = async () => {
                let hasError = false
                serverErrors.value = {
                    season_id: null,
                    market_id: null,
                    startDate: null,
                    endDate: null,
                }
                
                if(!market_id.value){
                    hasError = true
                    serverErrors.value.market_id = ['Select To CPP']
                }
                if(!startDate.value){
                    hasError = true
                    serverErrors.value.startDate = ['Select To Date']
                }
                if(!endDate.value){
                    hasError = true
                    serverErrors.value.endDate = ['Select From Date']
                }

                if(!hasError){
                    saving.value = true
                    await window.open(`${ axios.defaults.baseURL }/reports/transport?market=${ market_id.value }&startDate=${ startDate.value}&endDate=${ endDate.value}&season_id=${ season_id.value}`, '_blank')
                    saving.value = false
                }
            }

            const generateReport2 = async () => {
                let hasError = false
                serverErrors.value = {
                    market_id: null,
                    cNumber: null,
                    startDate: null,
                    endDate: null,
                }
                
                if(!market_id.value){
                    hasError = true
                    serverErrors.value.market_id = ['Select To CPP']
                }
                if(!cNumber.value){
                    hasError = true
                    serverErrors.value.cNumber = ['Input Consignment Number']
                }
                if(!startDate.value){
                    hasError = true
                    serverErrors.value.startDate = ['Select To Date']
                }
                if(!endDate.value){
                    hasError = true
                    serverErrors.value.endDate = ['Select From Date']
                }

                if(!hasError){
                    saving.value = true
                    await window.open(`${ axios.defaults.baseURL }/reports/transport?market=${ market_id.value }&startDate=${ startDate.value}&endDate=${ endDate.value}&cNumber=${ cNumber.value}`, '_blank')
                    saving.value = false
                }
            }

            const generateReport3 = async () => {
                let hasError = false
                serverErrors.value = {
                    market_id: null,
                    startDate: null,
                    endDate: null,
                    transporter_name: null,
                }
                
                if(!market_id.value){
                    hasError = true
                    serverErrors.value.market_id = ['Select To CPP']
                }
                if(!startDate.value){
                    hasError = true
                    serverErrors.value.startDate = ['Select To Date']
                }
                if(!endDate.value){
                    hasError = true
                    serverErrors.value.endDate = ['Select From Date']
                }
                if(!transporter_name.value){
                    hasError = true
                    serverErrors.value.transporter_name = ['Input Transporter Name']
                }

                if(!hasError){
                    saving.value = true
                    await window.open(`${ axios.defaults.baseURL }/reports/transport?market=${ market_id.value }&startDate=${ startDate.value}&endDate=${ endDate.value}&transporter_name=${ transporter_name.value}`, '_blank')
                    saving.value = false
                }
            }

            const generateReport4 = async () => {
                let hasError = false
                serverErrors.value = {
                    market_id: null,
                    startDate: null,
                    endDate: null,
                }
                
                if(!market_id.value){
                    hasError = true
                    serverErrors.value.market_id = ['Select To CPP']
                }
                if(!startDate.value){
                    hasError = true
                    serverErrors.value.startDate = ['Select To Date']
                }
                if(!endDate.value){
                    hasError = true
                    serverErrors.value.endDate = ['Select From Date']
                }

                if(!hasError){
                    saving.value = true
                    await window.open(`${ axios.defaults.baseURL }/reports/transport?marketId=${ market_id.value }&startDate=${ startDate.value}&endDate=${ endDate.value}`, '_blank')
                    saving.value = false
                }
            }

            return {
                dataForm,
                myModal,
                saving,
                serverErrors,
                seasons,
                season_id,
                markets,
                market_id,
                report_type,
                cNumber,
                transporter_name,
                startDate,
                endDate,
                sales,
                sale_id,
                growers,
                grower_id,
                number,
                changeSeason,
                changeMarket,
                changeSale,
                generateReport,
                generateReport2,
                generateReport3,
                generateReport4,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>